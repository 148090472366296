<script setup>
	const props = defineProps({
		params: {
			type: Object,
			required: true,
		},
	});

	const config = useRuntimeConfig();

	if (!process.dev) {
		ym(config.public.YANDEX_METRIKA_ID, "params", props.params);
	}
</script>

<template>
	<div class="yandex-metrika-params"></div>
</template>

<style lang="scss" scoped>
	.yandex-metrika-params {
		position: absolute;
		opacity: 0;
	}
</style>
