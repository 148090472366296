<template>
	<div class="layout">
		<Header :class="currentRoute === '/' ? 'header--mobile-show' : ''" />

		<main>
			<NuxtPage />
		</main>

		<LazyNavBar v-if="$viewport.isLessThan('md')" />

		<Footer />

		<YandexMetrikaParams :params="ymParams" />
	</div>
</template>

<script setup>
	import { useState, useRoute, useNuxtApp } from "#app";
	import { watch } from "vue";
	import { Header, Footer, LazyNavBar } from "#components";
	import { useMenuStore } from "@/store/menu/menu";
	import { useInfoStore } from "~/store/userInfo/userInfo";

	const { $viewport, api } = useNuxtApp();
	const route = useRoute();
	const currentRoute = useState("currentRoute", () => "");
	watch(
		route,
		(value) => {
			currentRoute.value = value.fullPath;
		},
		{ deep: true, immediate: true },
	);

	const menuStore = useMenuStore();
	const infoStore = useInfoStore();

	if ($viewport.isGreaterOrEquals("md")) await menuStore.fetchMenu();
	await infoStore.fetchInfo();
	const { login, name, lastname } = infoStore.getInfo;

	const { data: ip } = await $useFetch(api.userIp, {
		headers: {},
		transform: (mutations) => mutations.data.ip,
	});

	const ymParams = ref({
		Логин: login,
		Имя: `${name} ${lastname}`,
		IP: ip.value,
	});
</script>
